// extracted by mini-css-extract-plugin
export var active = "Openai-module--active--Lc0w3";
export var loader = "Openai-module--loader--1yhIS";
export var openai = "Openai-module--openai--1egeQ";
export var openaiAdvantages = "Openai-module--openaiAdvantages--36AZe";
export var openaiAdvantagesContent = "Openai-module--openaiAdvantagesContent--2n5vq";
export var openaiAdvantagesList = "Openai-module--openaiAdvantagesList--24yo2";
export var openaiAdvantagesListSecondPara = "Openai-module--openaiAdvantagesListSecondPara--2QfCr";
export var openaiContent = "Openai-module--openaiContent--3VmkQ";
export var openaiCtas = "Openai-module--openaiCtas--2CRVh";
export var openaiCtasFilled = "Openai-module--openaiCtasFilled--1-MEe";
export var openaiCtasOutline = "Openai-module--openaiCtasOutline--1shhM";
export var openaiDemo = "Openai-module--openaiDemo--24m0B";
export var openaiDemoContent = "Openai-module--openaiDemoContent--2ftJq";
export var openaiDemoForm = "Openai-module--openaiDemoForm--1ySH5";
export var openaiDemoFormButton = "Openai-module--openaiDemoFormButton--3bNZX";
export var openaiDemoFormFields = "Openai-module--openaiDemoFormFields--3QMoc";
export var openaiDemoRow = "Openai-module--openaiDemoRow--1Jzwe";
export var openaiFeatures = "Openai-module--openaiFeatures--2dRGQ";
export var openaiFeaturesList = "Openai-module--openaiFeaturesList--W6lB5";
export var openaiHalfSection = "Openai-module--openaiHalfSection--2QAfT";
export var openaiHalfSectionTitle = "Openai-module--openaiHalfSectionTitle--Mjpfq";
export var openaiSplashTitle = "Openai-module--openaiSplashTitle--2tJ2S";
export var openaiVideo = "Openai-module--openaiVideo--LDDxZ";
export var openaiXCanonic = "Openai-module--openaiXCanonic--1rR1j";
export var showIn = "Openai-module--show-in--2hRKe";
export var success = "Openai-module--success--1j6dT";
export var successTitle = "Openai-module--successTitle--3iayZ";