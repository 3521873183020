import React from "react"

import Layout from "@src/components/Layout"
import Button from "@src/components/Button"
import getAppUrl from "@src/utils/getAppUrl"
import ImageRow from "../V2/Features/components/ImageRow"
import Row from "../V2/components/Row"
import DotBG from "../V2/components/DotBG/DotBG"
import Features from "../V2/Features/components/Features/Features"
import useDemoForm from "@src/hooks/useDemoForm"
import Loader from "@src/components/Loader"

import OpenAiLogo from "@src/icons/openai/openai.svg"
import Placeholder from "@src/icons/case-studies/placeholder.png"
import CreateCompletion from "@src/icons/openai/create_completion.svg"
import CreateEmbeddings from "@src/icons/openai/create_embeddings.svg"
import CreateImage from "@src/icons/openai/create_image.svg"
import CreateModeration from "@src/icons/openai/create_moderation.svg"
import ListFiles from "@src/icons/openai/list_files.svg"
import RetrieveFile from "@src/icons/openai/retrieve_file.svg"
import RetrieveFileContent from "@src/icons/openai/retrieve_file_content.svg"
import CreateNewEdits from "@src/icons/openai/create_new_edits.svg"
import IncreasedProductivity from "@src/icons/openai/increased_productivity.png"
import CustomerExperience from "@src/icons/openai/customer_experience.png"
import ImprovedAccuracy from "@src/icons/openai/improved_accuracy.png"
import OpenAiXCanonic from "@src/icons/openai/openai_canonic.svg"
import Video from "@src/videos/openai.mp4"
import * as styles from "./Openai.module.scss"

const OpenAi = () => {
  const appUrl = getAppUrl.hook()
  const { form, setForm, errors, submit, loading, setOriginPage } =
    useDemoForm()
  const loadingClassName = loading === "LOADING" ? styles.active : ""
  const successClassName = loading === "SUCCESS" ? styles.active : ""

  React.useEffect(() => {
    setOriginPage("Open AI")
  }, [])

  return (
    <Layout
      title="Open AI"
      description="Build internal tools with Open AI integration and revolutionize business"
      url="https://canonic.dev/openai"
    >
      <section>
        <Row>
          <div className={styles.openai}>
            <div className={styles.openaiContent}>
              <img src={OpenAiLogo} alt={"OpenAI Logo"} />
              <div>
                <h1 className={styles.openaiSplashTitle}>
                  Build internal tools with Open AI integration and
                  revolutionize business
                </h1>
                <div className={styles.openaiCtas}>
                  <a href={"#get-a-demo"}>
                    <Button isPrimary className={styles.openaiCtasFilled}>
                      Get a Demo
                    </Button>
                  </a>
                  <a href={appUrl} target="_blank" rel="noopener noreferrer">
                    <Button className={styles.openaiCtasOutline}>
                      Try for free
                    </Button>
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.openaiVideo}>
              <video autoPlay={true} playsInline muted loop src={Video} />
            </div>
          </div>
        </Row>
      </section>

      <Row>
        <DotBG height="250%" />
        <section>
          <div className={styles.openaiHalfSection}>
            <h3 className={styles.openaiHalfSectionTitle}>
              Supported Features
            </h3>
            <p>
              The digital landscape today is dominated by artificial
              intelligence, which has become an essentiality in today's
              fast-paced, competitive business environment.{" "}
            </p>
            <p>
              Canonic makes AI integration effortless for you to harness the
              power of AI and transform the way you work.
            </p>
          </div>
        </section>
      </Row>
      <Row className={styles.openaiFeatures}>
        <Features
          numPerRow={2}
          horizontal
          className={styles.openaiFeaturesList}
          features={[
            {
              title: "Create Completion",
              icon: CreateCompletion,
              subtitle:
                "Creates a completion for the provided prompt and parameters.",
            },
            {
              title: "Create Embeddings",
              icon: CreateEmbeddings,
              subtitle: "Creates an embedding vector representing text input.",
            },
            {
              title: "Create Image",
              icon: CreateImage,
              subtitle: "When given a prompt, it generates an image.",
            },
            {
              title: "Create Moderation",
              icon: CreateModeration,
              subtitle: "Classifies if text violates OpenAI’s Content Policy.",
            },
            {
              title: "Get List of Files",
              icon: ListFiles,
              subtitle:
                "Returns a list of files that belong to the user’s org.",
            },
            {
              title: "Retrieve File",
              icon: RetrieveFile,
              subtitle: "Returns information about a specific file.",
            },
            {
              title: "Retrieve File Content",
              icon: RetrieveFileContent,
              subtitle: "Returns the content of the specified file",
            },
            {
              title: "Create a new Edit",
              icon: CreateNewEdits,
              subtitle:
                "Creates a new edit for the provided input, instruction, and parameters.",
            },
          ]}
        />
      </Row>

      <Row className={styles.openaiAdvantages}>
        <DotBG reverse />
        <div className={styles.openaiAdvantagesContent}>
          <h3 className={styles.openaiHalfSectionTitle}>
            Maximize your potential with OpenAI integration
          </h3>
        </div>
        <Features
          numPerRow={3}
          className={styles.openaiAdvantagesList}
          features={[
            {
              title: <h5>Increased Productivity</h5>,
              icon: IncreasedProductivity,
              subtitle: (
                <p>
                  Integrating OpenAI with other systems can help
                  <b>automate tasks and make workflows more efficient</b>,
                  freeing up valuable time for employees to focus on
                  higher-level tasks.
                </p>
              ),
            },
            {
              title: <h5>Boost Customer Experience</h5>,
              icon: CustomerExperience,
              subtitle: (
                <>
                  <p>
                    OpenAI can be used to <b>enhance customer interactions</b>,
                    such as through chatbots or virtual assistants.
                  </p>
                  <p className={styles.openaiAdvantagesListSecondPara}>
                    This can help improve the overall customer experience by
                    providing
                    <b>
                      quick and accurate responses to customer inquiries
                    </b>{" "}
                    and helping to resolve issues more efficiently.
                  </p>
                </>
              ),
            },
            {
              title: <h5>Improved Accuracy</h5>,
              icon: ImprovedAccuracy,
              subtitle: (
                <>
                  <p>
                    OpenAI models are trained on large amounts of data, which
                    allows them to make predictions with a high degree of
                    accuracy.
                  </p>
                  <p className={styles.openaiAdvantagesListSecondPara}>
                    Integrating OpenAI can help improve the overall{" "}
                    <b>
                      accuracy of your data analysis and decision-making
                      processes.
                    </b>
                  </p>
                </>
              ),
            },
          ]}
        />
      </Row>
      <Row>
        <ImageRow
          screenshot={OpenAiXCanonic}
          title={"Effortlessly integrate OpenAI with Canonic"}
          subtitle={
            <>
              <p>
                Unlock the Full Potential of OpenAI with Canonic's Seamless
                Integration Solutions. We are dedicated to making AI adoption
                easy for businesses, helping them to streamline workflows,
                increase efficiency, and enhance the overall customer
                experience.
              </p>

              <p>
                Experience a smooth and effortless integration process and reap
                the many benefits of OpenAI technology.
              </p>
            </>
          }
          className={styles.openaiXCanonic}
        />
        <span id={"get-a-demo"} />
      </Row>
      <Row className={styles.openaiDemoRow}>
        <DotBG reverse />

        <div className={styles.openaiDemo}>
          <div className={styles.openaiDemoContent}>
            <h3>Excited to know more? Register for a free demo today.</h3>
            <p>
              New to Canonic? Schedule a 1:1 session with an expert from our
              team to learn more on how the Open AI integration works and ways
              to connect it to build a tool.
            </p>
          </div>

          <div className={styles.openaiDemoForm}>
            <div className={styles.openaiDemoFormFields}>
              <input
                type="text"
                name="first-name"
                value={form.firstName}
                onChange={setForm("firstName")}
                placeholder="First Name*"
              />
              <input
                type="text"
                name="last-name"
                value={form.lastName}
                onChange={setForm("lastName")}
                placeholder="Last Name*"
              />
              <input
                type="text"
                name="company"
                value={form.company}
                onChange={setForm("company")}
                placeholder="Company*"
              />
              <input
                type="text"
                name="email"
                value={form.email}
                onChange={setForm("email")}
                placeholder="Work Email*"
              />
              <input
                type="text"
                name="phone"
                value={form.phone}
                onChange={setForm("phone")}
                placeholder="Phone Number"
              />

              <Button
                isPrimary
                onClick={e => {
                  submit(e)
                }}
                className={styles.openaiDemoFormButton}
              >
                <Loader className={`${styles.loader} ${loadingClassName}`} />
                Get a Demo
              </Button>
            </div>
            <div className={`${styles.success} ${successClassName}`}>
              <div className={styles.successTitle}>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="15"
                    viewBox="0 0 17 15"
                  >
                    <g
                      fill="none"
                      fillRule="evenodd"
                      transform="translate(-0.7 -2)"
                    >
                      <path
                        fill="#000000"
                        d="M7.85249641,9.71389594 L11.6493699,5.76645583 C12.1034454,5.29392678 12.842726,5.29392678 13.3033684,5.773422 C13.7315568,6.2364387 13.7262743,6.9693848 13.2866867,7.42676486 L8.66487451,12.2335442 C8.21079909,12.7060732 7.47151849,12.7060732 7.01602829,12.2320658 L4.71197867,9.81464903 C4.2686357,9.3532886 4.2686357,8.61501939 4.71197867,8.15436853 C5.16605409,7.68183948 5.90533468,7.68183948 6.36005026,8.15503594 L7.85249641,9.71389594 Z"
                      />
                    </g>
                  </svg>
                </div>
                <h3>We have received your request</h3>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </Layout>
  )
}

export default OpenAi
